import { HttpClient } from '@angular/common/http';
import { inject, Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SignalsSimpleStoreService } from '@app/shared/store';
import { environment } from '@env/environment';
import { JwtPayload } from 'jsonwebtoken';
import { jwtDecode } from 'jwt-decode';
import { catchError, firstValueFrom, interval, map, Observable, of, startWith, Subscription, tap } from 'rxjs';
import { ApiClientState } from './api-client-state';
import { initialStateFactory } from './initial-state-factory';
import { UploadedDocument } from '@lead-in/core';

// defined outside of class because in development preview the constructor is called twice
// and the interval runs also twice, so that one could get logged out even when the session was renewed
let timeout: number;

@Injectable({ providedIn: 'root' })
export class ApiClientService extends SignalsSimpleStoreService<ApiClientState> implements OnDestroy {
    private readonly http = inject(HttpClient);
    private readonly router = inject(Router);
    private readonly snackbar = inject(MatSnackBar);

    intervallSubscription: Subscription | undefined;
    localUploadedDocument: UploadedDocument | undefined;

    constructor() {
        super(initialStateFactory);

        const sessionEnd = this.get('sessionEnd');
        if (sessionEnd > Date.now()) {
            this.handleTimeout(sessionEnd);
        }
    }

    /**
     * Attempts to make the API send a magic link to the customer
     * @param email (verified) email of the customer
     * @returns success
     */
    sendMagicLink(email: string): Promise<boolean> {
        return this.makeApiCall({
            apiFn: this.http.get(environment.apiUrl + '/send-magic-link', {
                params: { email },
                observe: 'response',
            }),
            mapFn: (response) => response.ok,
            onError: (err) => {
                const status = (err as Record<string, unknown>)?.['status'];
                if (typeof status === 'number' && status >= 400 && status < 500) {
                    this.handleError(err, 'E-Mail nicht bekannt oder nicht freigeschaltet.');
                    return false;
                }
                return;
            },
        });
    }

    /**
     * Retrieves an access token for the given key
     * if the corresponding customer is allowed to
     * access self service.
     * @param key parameter of the magic link
     * @returns success
     */
    startSession(key: string): Promise<boolean> {
        return this.makeApiCall({
            apiFn: this.http.get(environment.apiUrl + '/start-session', {
                params: { key },
                responseType: 'text',
            }),
            errMsg: 'Dieser Link ist abgelaufen. Sie können auf dieser Seite einen neuen Zugangslink anfordern.',
            onSuccess: this.handleToken.bind(this),
        });
    }

    /**
     * Refreshes the access token
     * @returns success
     */
    renewSession(): Promise<boolean> {
        return this.makeApiCall({
            apiFn: this.http.get(environment.apiUrl + '/renew-session', {
                responseType: 'text',
            }),
            onSuccess: this.handleToken.bind(this),
        });
    }

    /**
     * Retrieves information about uploaded documents of the customer
     * @returns success
     */
    getDocumentTypes(): Promise<boolean> {
        return this.makeApiCall({
            apiFn: this.http.get<{ id: string; name: string }[]>(environment.apiUrl + '/get-document-types'),
            onSuccess: (documentTypes) => {
                this.set('documentTypes', documentTypes);
            },
        });
    }

    /** Retrieves information about uploaded documents of the customer.
     * This method fetches the list of documents uploaded by the customer and updates the local state.
     * It also checks if there's a locally stored document that hasn't been synced yet and either removes it
     * if it's found in the fetched list or adds it to the list if it's not found.
     * @returns A promise that resolves to a boolean indicating the success of the operation.
     */
    getDocumentsInfo(): Promise<boolean> {
        return this.makeApiCall({
            apiFn: this.http.get<UploadedDocument[]>(environment.apiUrl + '/get-documents-info'),
            onSuccess: (uploadedDocuments) => {
                if (this.localUploadedDocument !== undefined) {
                    if (
                        uploadedDocuments.find((doc) => this.localUploadedDocument?.uploadedAt === doc.uploadedAt) !==
                        undefined
                    ) {
                        this.localUploadedDocument = undefined;
                    } else {
                        uploadedDocuments.push(this.localUploadedDocument);
                    }
                }
                this.set('uploadedDocuments', uploadedDocuments);
            },
        });
    }

    /**
     * Initiates the upload of a document to the server.
     * This method prepares and sends a POST request to the server with the specified document type and file.
     * It returns a promise that resolves to a boolean indicating the success of the operation.
     *
     * @param documentType The type of the document being uploaded.
     * @param file The file to be uploaded.
     * @param intervall Optional interval in milliseconds for polling new document uploads.
     * @returns A promise that resolves to a boolean indicating the success of the operation.
     */
    uploadDocument(documentType: string, file: File, intervall?: number | undefined): Promise<boolean> {
        const formData = new FormData();
        formData.append('documentType', documentType);
        formData.append('file', file);
        return this.makeApiCall({
            apiFn: this.http.post<UploadedDocument>(environment.apiUrl + '/upload-document', formData, {
                headers: {
                    Enctype: 'multipart/form-data',
                },
            }),
            errMsg: 'Hochladen fehlgeschlagen',
            onSuccess: async (uploadedDocument) => {
                uploadedDocument.new = true;
                this.set('uploadedDocuments', (uploadedDocuments: UploadedDocument[]) => [
                    ...uploadedDocuments,
                    uploadedDocument,
                ]);
                this.localUploadedDocument = uploadedDocument;
                await this.renewSession();
                if (intervall !== undefined) {
                    this.intervallSubscription = interval(intervall)
                        .pipe(
                            startWith(0),
                            tap(async () => {
                                await this.getDocumentsInfo();
                                if (this.get('uploadedDocuments').find((d) => d.new) === undefined) {
                                    this.stopPolling();
                                }
                            })
                        )
                        .subscribe();
                }
            },
        });
    }

    deleteDocument(documentType: string, uploadedAt: number): Promise<boolean> {
        return this.makeApiCall({
            apiFn: this.http.get(environment.apiUrl + '/delete-document', {
                params: {
                    documentType,
                    uploadedAt,
                },
            }),
            errMsg: 'Löschen fehlgeschlagen',
        });
    }

    ngOnDestroy(): void {
        this.stopPolling();
    }

    /** executes an API call with default error handling and loading state */
    private makeApiCall<T>(config: {
        /** HttpClient method like `this.http.get` */
        apiFn: Observable<T>;
        /** will be displayed to user! */
        errMsg?: string;
        /** optional mapping to bool (otherwise its true when the call was successful) */
        mapFn?: (v: T) => boolean;
        /** callback to set the state */
        onSuccess?: (v: T) => void | Promise<void>;
        /** optional error handler. if it returns false the default error handling will be disabled! */
        onError?: (err: unknown) => void | false;
    }): Promise<boolean> {
        this.set('loading', true);
        return firstValueFrom(
            config.apiFn.pipe(
                tap(config.onSuccess),
                map((v) => {
                    if (typeof config.mapFn === 'function') {
                        return config.mapFn(v);
                    }
                    return true;
                }),
                catchError((err) => {
                    if (typeof config.onError === 'function') {
                        const result = config.onError(err);
                        if (typeof result === 'boolean') {
                            return of(result);
                        }
                    }
                    this.handleError(err, config.errMsg ?? 'Unbekannter Fehler');
                    return of(false);
                })
            )
        ).finally(() => {
            this.set('loading', false);
        });
    }

    /** (re)starts an auto-lougout */
    private handleTimeout(sessionEnd: number): void {
        if (timeout !== undefined) {
            clearTimeout(timeout);
        }
        timeout = window.setInterval(() => {
            if (sessionEnd < Date.now() + 600) {
                this.router.navigate(['/login']);
                this.handleError('session timed out', 'Sitzung abgelaufen!');
                clearTimeout(timeout);
            }
        }, 500);
    }

    /** stores jwt in localStorage and updates store */
    private handleToken(token: string): void {
        localStorage.setItem('access_token', token);
        const decoded = jwtDecode<JwtPayload>(token);
        if (decoded === null || decoded.exp === undefined) {
            this.resetState();
            throw new Error('Ungültiger Token');
        }
        const fullName = decoded['fullName'];
        const sessionEnd = decoded.exp * 1_000;
        this.handleTimeout(sessionEnd);
        this.setState({ fullName, sessionEnd, token });
    }

    /** write error to console (if in prod env) and display snackbar to user */
    private handleError(err: unknown, message: string): void {
        if (!environment.production) {
            console.error(err);
        }
        this.snackbar.open(message, 'Ok', { duration: 30_000 });
    }

    private stopPolling(): void {
        if (this.intervallSubscription) {
            this.intervallSubscription?.unsubscribe();
            this.intervallSubscription = undefined;
        }
    }
}
