@if (loading()) {
    <div class="spinner"><mat-spinner></mat-spinner></div>
} @else {
    <h1>Anmeldung</h1>
    <form [formGroup]="loginForm" (ngSubmit)="login()">
        <mat-form-field>
            <mat-label>E-Mail</mat-label>
            <input
                matInput
                type="email"
                placeholder="z.B. name@anbieter.com"
                required
                formControlName="email"
                [errorStateMatcher]="matcher"
            />
            <mat-hint>Deine bei GFN registrierte E-Mail-Adresse</mat-hint>
            @if (loginForm.controls.email.hasError('required')) {
                <mat-error
                    >E-Mail ist ein <strong>Plichtfeld</strong></mat-error
                >
            } @else if (loginForm.controls.email.hasError('email')) {
                <mat-error>Bitte gib eine gültige E-Mail-Adresse ein</mat-error>
            }
        </mat-form-field>
        <br />
        <br />
        <button
            mat-flat-button
            [disabled]="
                loginForm.controls.email.value === '' ||
                !loginForm.controls.email.valid
            "
            type="submit"
        >
            Anmelden
        </button>
    </form>
}
