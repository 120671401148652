import { Signal, WritableSignal, computed, signal } from '@angular/core';

/**
 * Simple signals store
 * Source: https://medium.com/ngconf/keeping-state-with-a-service-using-signals-bee652158ecf
 *
 * @example
 * type StateType = {
 *   someKey: string;
 * };
 *
 * Injectable()
 * export class SomeStateService extends SignalsSimpleStoreService<StateType> {
 *   constructor() {
 *     super({ someKey: 'some value' });
 *   }
 * }
 */
export abstract class SignalsSimpleStoreService<T extends Record<string, unknown>> {
    protected state: WritableSignal<T>;
    private initialState: T | (() => T);

    constructor(initialState: T | (() => T)) {
        this.initialState = initialState;
        if (typeof initialState === 'function') {
            this.state = signal(initialState());
        } else {
            this.state = signal(initialState);
        }
    }

    /**
     * Returns a reactive value for a property on the state.
     * This is used when the consumer needs the signal for
     * specific part of the state.
     *
     * @param key - the key of the property to be retrieved
     */
    public select<K extends keyof T>(key: K): Signal<T[K]> {
        return computed(() => this.state()[key]);
    }

    /**
     * Returns the current value for a property on the state.
     * This is used when the consumer needs no updates, just
     * the current state.
     *
     * @param key - the key of the property to be retrieved
     */
    public get<K extends keyof T>(key: K): T[K] {
        return this.state()[key];
    }

    /**
     * This is used to set a new value for a property
     *
     * @param key - the key of the property to be set
     * @param data - the new data to be saved
     */
    protected set<K extends keyof T>(key: K, data: T[K]): void;
    /**
     * This is used to set a new value for a property
     *
     * @param key - the key of the property to be set
     * @param updateFn - function for mutating the properties current value
     */
    protected set<K extends keyof T>(key: K, updateFn: (oldValue: T[K]) => T[K]): void;
    protected set<K extends keyof T>(key: K, dataOrUpdateFn: T[K] | ((oldValue: T[K]) => T[K])): void {
        if (typeof dataOrUpdateFn === 'function') {
            this.state.update((currentValue) => ({
                ...currentValue,
                // eslint-disable-next-line @typescript-eslint/ban-types
                [key]: (dataOrUpdateFn as Function)(currentValue[key]),
            }));
        } else {
            this.state.update((currentValue) => ({
                ...currentValue,
                [key]: dataOrUpdateFn,
            }));
        }
    }

    /**
     * Sets values for multiple properties on the store
     * This is used when there is a need to update multiple
     * properties in the store
     *
     * @param partialState - the partial state that includes
     *                      the new value to be saved
     */
    protected setState(partialState: Partial<T>): void {
        this.state.update((currentValue) => ({
            ...currentValue,
            ...partialState,
        }));
    }

    /**
     * Resets the values for all properties on the store
     * to their initial values
     */
    protected resetState(): void {
        const initialState = typeof this.initialState === 'function' ? this.initialState() : this.initialState;
        this.state.update(() => initialState);
    }
}
