import { Routes } from '@angular/router';
import {
    DashboardComponent,
    EmailSentComponent,
    LoginComponent,
    PageNotFoundComponent,
} from '@app/pages';
import { AuthGuard } from '@app/shared';
import { PortalComponent } from './pages/portal/portal.component';

export const routes: Routes = [
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
    },
    { path: 'email-sent', component: EmailSentComponent },
    { path: 'login', component: LoginComponent },
    { path: 'portal', component: PortalComponent, canActivate: [AuthGuard] },
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: '**', component: PageNotFoundComponent },
];
