<mat-toolbar role="heading" aria-level="1">
    <img src="/logo-light.svg" alt="GFN Logo" height="40" width="106" />
    <span>Kunden Service Center</span>
</mat-toolbar>
<main>
    <div>
        <router-outlet />
    </div>
</main>
<div class="footer">
    <span class="footer-link imprint" title="Impressum" (click)="openImprint()"
        >Impressum</span
    >
    <span>&middot;</span>
    <span
        class="footer-link privacy-policy"
        title="Datenschutzhinweise"
        (click)="openPrivacyPolicy()"
        >Datenschutzhinweise</span
    >
</div>
