import { inject } from '@angular/core';
import { CanActivateFn, RedirectCommand, Router } from '@angular/router';
import { ApiClientService } from '../api-client';

export const AuthGuard: CanActivateFn = (route) => {
    if (route.url[0]?.path === 'portal') {
        if (route.queryParamMap.has('key')) {
            return true;
        }
    } else {
        const apiClient = inject(ApiClientService);
        const sessionEnd = apiClient.get('sessionEnd');
        if (sessionEnd > Date.now()) return true;
    }

    const router = inject(Router);
    const loginPath = router.parseUrl('/login');
    return new RedirectCommand(loginPath, {
        replaceUrl: true,
    });
};
