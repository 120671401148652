import { JwtPayload } from 'jsonwebtoken';
import { jwtDecode } from 'jwt-decode';
import { ApiClientState } from './api-client-state';

export const initialStateFactory = (): ApiClientState => {
    const state: ApiClientState = {
        fullName: '',
        uploadedDocuments: [],
        documentTypes: [],
        loading: false,
        sessionEnd: Date.now(),
    };
    const token = localStorage.getItem('access_token');
    if (token === null) {
        return state;
    }
    state.token = token;
    const decoded = jwtDecode<JwtPayload>(token);
    state.fullName = decoded['fullName'] ?? '';
    state.sessionEnd =
        decoded.exp === undefined ? Date.now() : decoded.exp * 1_000;
    return state;
};
