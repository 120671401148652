import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    inject,
    SecurityContext,
    Signal,
    viewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ACCEPTED_FILE_TYPES, ACCEPTED_FILE_TYPES_HUMAN_READABLE, MAX_UPLOAD_FILE_SIZE } from '@lead-in/core';
import { map } from 'rxjs';

export interface SelectDocumentDialogData {
    documentTypes: Signal<{ id: string; name: string }[]>;
}

export interface SelectDocumentDialogResult {
    file: File;
    documentType: string;
}

@Component({
    selector: 'app-select-document-dialog',
    templateUrl: './select-document-dialog.component.html',
    styleUrl: './select-document-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatButtonModule, MatDialogModule, MatFormFieldModule, MatIconModule, MatInputModule, ReactiveFormsModule],
})
export class SelectDocumentDialogComponent {
    readonly ACCEPTED_FILE_TYPES = ACCEPTED_FILE_TYPES.join(',');
    readonly ACCEPTED_FILE_TYPES_HUMAN_READABLE = ACCEPTED_FILE_TYPES_HUMAN_READABLE.join(', ');
    readonly MAX_UPLOAD_FILE_SIZE = MAX_UPLOAD_FILE_SIZE;

    data: SelectDocumentDialogData = inject(MAT_DIALOG_DATA);

    readonly documentTypeFormControl = new FormControl<DocumentType | null>(null, Validators.required);
    readonly filesFormControl = new FormControl<string | null>(null, Validators.required);
    readonly formGroup = new FormGroup({
        documentType: this.documentTypeFormControl,
        files: this.filesFormControl,
    });

    private readonly dialogRef: MatDialogRef<SelectDocumentDialogComponent, SelectDocumentDialogResult> =
        inject(MatDialogRef);
    private readonly fileInput = viewChild<ElementRef<HTMLInputElement>>('fileInput');

    readonly selectedFileName = toSignal(
        this.filesFormControl.valueChanges.pipe(map(() => this.fileInput()?.nativeElement.files?.item(0)?.name ?? ''))
    );

    private readonly sanitizer = inject(DomSanitizer);
    private readonly snackbar = inject(MatSnackBar);

    /** just closes the dialog */
    cancel(): void {
        this.dialogRef.close();
    }

    /** if all data was selected submits it to the dialog caller and closes the dialog */
    submit(): void {
        try {
            if (this.formGroup.invalid) {
                throw new Error('Ungültige Eingabe');
            }
            const file = this.fileInput()?.nativeElement.files?.item(0) ?? null;
            if (file === null || typeof file.type !== 'string') {
                throw new Error('Ungültige Datei');
            }
            if (!ACCEPTED_FILE_TYPES.includes(file.type)) {
                throw new Error('Ungültige Dateiart');
            }
            if (file.size > MAX_UPLOAD_FILE_SIZE) {
                throw new Error(`Datei zu groß (max ${MAX_UPLOAD_FILE_SIZE / 1_000_000} MB)`);
            }
            const documentType = this.sanitizer.sanitize(SecurityContext.HTML, this.documentTypeFormControl.value);
            if (typeof documentType !== 'string') {
                throw new Error('Ungültige Dokumentart');
            }
            this.dialogRef.close({
                file,
                documentType,
            });
        } catch (err) {
            this.snackbar.open(`${err}`.replace('Error', 'Fehler'), 'Ok', { duration: 30_000 });
        }
    }
}
