import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    computed,
    input,
    output,
    signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'app-renew-session-timer',
    templateUrl: './renew-session-timer.component.html',
    styleUrl: './renew-session-timer.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatButtonModule, MatIconModule, MatTooltipModule],
})
export class RenewSessionTimerComponent implements OnDestroy {
    readonly disabled = input<boolean>();
    readonly sessionEnd = input.required<number>();
    readonly currentTime = signal<number>(Date.now());
    readonly timeLeft = computed(() => {
        const diffInSec = (this.sessionEnd() - this.currentTime()) / 1_000;
        const min = Math.floor(diffInSec / 60);
        const sec = Math.floor(diffInSec % 60);
        return `${min}:${sec.toString().padStart(2, '0')}`;
    });

    readonly renewSession = output<void>();

    private readonly interval = setInterval(() => {
        this.currentTime.update(() => Date.now());
    }, 1000);

    ngOnDestroy(): void {
        clearInterval(this.interval);
    }
}
