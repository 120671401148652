import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-email-sent',
    templateUrl: './email-sent.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [],
})
export class EmailSentComponent {}
