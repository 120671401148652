<h1>Hallo {{ fullName() }}</h1>

<app-renew-session-timer
    [disabled]="loading()"
    [sessionEnd]="sessionEnd()"
    (renewSession)="renewSession()"
></app-renew-session-timer>

<app-uploaded-documents-list
    [loading]="loading()"
    [documentTypes]="documentTypes()"
    [uploadedDocuments]="uploadedDocuments()"
    (deleteDocumentClick)="deleteDocument($event[0], $event[1])"
></app-uploaded-documents-list>

@if (newDocumentUploaded()) {
    <br /><br />
    <mat-card appearance="outlined">
        <mat-card-content>
            Neu hochgeladene Dateien werden zunächst einer Virenprüfung
            unterzogen. Dokumente öffnen oder löschen geht erst, wenn dies
            abgeschlossen ist. Es wird alle {{ INTERVAL / 1000 }} Sekunden
            automatisch aktualisiert.
        </mat-card-content>
        <mat-card-actions>
            <mat-progress-bar mode="buffer"></mat-progress-bar>
        </mat-card-actions>
    </mat-card>
}

<div class="spacer"></div>

<app-select-document-button
    [documentTypes]="documentTypes()"
    [disabled]="loading()"
    (fileSelect)="uploadDocument($event.documentType, $event.file)"
></app-select-document-button>
