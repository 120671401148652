import { BreakpointObserver } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    DestroyRef,
    inject,
    input,
    OnInit,
    output,
    signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AlertComponent, AlertData } from '@app/components';
import { UploadedDocument } from '@lead-in/core';

@Component({
    selector: 'app-uploaded-documents-list',
    templateUrl: './uploaded-documents-list.component.html',
    styleUrl: './uploaded-documents-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        DatePipe,
        MatButtonModule,
        MatChipsModule,
        MatDialogModule,
        MatIconModule,
        MatListModule,
        MatProgressBarModule,
        MatTooltipModule,
    ],
})
export class UploadedDocumentsListComponent implements OnInit {
    readonly loading = input<boolean>();
    readonly documentTypes = input.required<{ id: string; name: string }[]>();
    readonly uploadedDocuments = input.required<UploadedDocument[]>();
    readonly docTypesMap = computed(() =>
        this.documentTypes().reduce(
            (a, c) => {
                a[c.id] = c.name;
                return a;
            },
            {} as Record<string, string>
        )
    );

    /** emits a tuple of the type and uploadedAt timestamp of an uploaded document */
    readonly downloadDocumentClick = output<[string, number]>();

    /** emits a tuple of the type and uploadedAt timestamp of an uploaded document */
    readonly deleteDocumentClick = output<[string, number]>();

    readonly hideIcon = signal<boolean>(false);

    private readonly breakpointObserver = inject(BreakpointObserver);
    private readonly destroyRef = inject(DestroyRef);
    private readonly dialog = inject(MatDialog);

    ngOnInit(): void {
        this.breakpointObserver
            .observe('(max-width: 440px)')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((viewportWidthUnder440px) => {
                this.hideIcon.set(viewportWidthUnder440px.matches);
            });
    }

    openDialog(comment?: string): void {
        if (!comment) return;
        this.dialog.open<AlertComponent, AlertData, void>(AlertComponent, {
            data: {
                title: 'Bemerkung',
                text: comment,
            },
        });
    }
}
