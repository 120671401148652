import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiClientService } from '@app/shared';

@Component({
    selector: 'app-portal',
    templateUrl: './portal.component.html',
    styleUrl: './portal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatProgressSpinnerModule],
})
export class PortalComponent {
    private readonly apiClient = inject(ApiClientService);
    private readonly route = inject(ActivatedRoute);
    private readonly router = inject(Router);

    constructor() {
        const key = this.route.snapshot.queryParamMap.get('key');
        if (typeof key !== 'string') {
            this.router.navigate(['/login']);
            return;
        }
        this.apiClient.startSession(key).then((success) => {
            if (success) {
                this.router.navigate(['/dashboard']);
            }
        });
    }
}
