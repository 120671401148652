import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Meta } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatToolbarModule, RouterOutlet],
})
export class AppComponent {
    private readonly meta = inject(Meta);

    constructor() {
        this.meta.addTag({
            name: 'description',
            content: 'Lade deine persönlichen Dokumente hoch.',
        });
    }

    openImprint(): void {
        window.open('https://www.gfn.de/impressum', '_blank', 'noopener');
    }

    openPrivacyPolicy(): void {
        window.open('https://www.gfn.de/datenschutzhinweise', '_blank', 'noopener');
    }
}
