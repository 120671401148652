import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import {
    RenewSessionTimerComponent,
    SelectDocumentButtonComponent,
    UploadedDocumentsListComponent,
} from '@app/components';
import { ApiClientService } from '@app/shared';
import { SELF_SERVICE_POLLING_INTERVAL } from '@lead-in/core';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrl: './dashboard.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatTableModule,
        RenewSessionTimerComponent,
        SelectDocumentButtonComponent,
        UploadedDocumentsListComponent,
    ],
    providers: [ApiClientService],
})
export class DashboardComponent {
    private readonly apiClient = inject(ApiClientService);
    readonly fullName = this.apiClient.select('fullName');
    readonly sessionEnd = this.apiClient.select('sessionEnd');
    readonly documentTypes = this.apiClient.select('documentTypes');
    readonly uploadedDocuments = this.apiClient.select('uploadedDocuments');
    readonly loading = this.apiClient.select('loading');
    readonly newDocumentUploaded = computed(() => this.uploadedDocuments().some((d) => d.new));

    /**
     * Interval in milliseconds for polling new document uploads (undefined for no polling).
     */
    INTERVAL = SELF_SERVICE_POLLING_INTERVAL;

    constructor() {
        this.fetchBasicData();
    }

    renewSession(): void {
        this.getDocumentsInfoAfterCommand(this.apiClient.renewSession());
    }

    uploadDocument(documentType: string, file: File): void {
        this.apiClient.uploadDocument(documentType, file, this.INTERVAL);
    }

    deleteDocument(documentType: string, uploadedAt: number): void {
        this.getDocumentsInfoAfterCommand(this.apiClient.deleteDocument(documentType, uploadedAt));
    }

    private getDocumentsInfoAfterCommand(command: Promise<boolean>) {
        return command.then((success) => {
            if (success) {
                this.fetchBasicData();
            }
        });
    }

    private fetchBasicData(): Promise<[boolean, boolean]> {
        return Promise.all([this.apiClient.getDocumentsInfo(), this.apiClient.getDocumentTypes()]);
    }
}
