import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import {
    RenewSessionTimerComponent,
    SelectDocumentButtonComponent,
    UploadedDocumentsListComponent,
} from '@app/components';
import { ApiClientService } from '@app/shared';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrl: './dashboard.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatTableModule,
        RenewSessionTimerComponent,
        SelectDocumentButtonComponent,
        UploadedDocumentsListComponent,
    ],
    providers: [ApiClientService],
})
export class DashboardComponent {
    private readonly apiClient = inject(ApiClientService);
    readonly fullName = this.apiClient.select('fullName');
    readonly sessionEnd = this.apiClient.select('sessionEnd');
    readonly documentTypes = this.apiClient.select('documentTypes');
    readonly uploadedDocuments = this.apiClient.select('uploadedDocuments');
    readonly loading = this.apiClient.select('loading');
    readonly newDocumentUploaded = computed(() => this.uploadedDocuments().some((d) => d.new));

    constructor() {
        this.fetchBasicData();
    }

    renewSession(): void {
        this.getDocumentsInfoAfterCommand(this.apiClient.renewSession());
    }

    uploadDocument(documentType: string, file: File): void {
        this.apiClient.uploadDocument(documentType, file);
    }

    downloadDocument(documentType: string, uploadedAt: number): void {
        this.apiClient.downloadDocument(documentType, uploadedAt);
    }

    deleteDocument(documentType: string, uploadedAt: number): void {
        this.getDocumentsInfoAfterCommand(this.apiClient.deleteDocument(documentType, uploadedAt));
    }

    private getDocumentsInfoAfterCommand(command: Promise<boolean>) {
        return command.then((success) => {
            if (success) {
                this.fetchBasicData();
            }
        });
    }

    private fetchBasicData(): Promise<[boolean, boolean]> {
        return Promise.all([this.apiClient.getDocumentsInfo(), this.apiClient.getDocumentTypes()]);
    }
}
