<p>
    <span>Automatische Abmeldung in {{ timeLeft() }}</span>
    <button
        mat-icon-button
        matTooltip="Sitzung verlängern"
        (click)="renewSession.emit()"
        [disabled]="disabled()"
    >
        <mat-icon>refresh</mat-icon>
    </button>
</p>
