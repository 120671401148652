<mat-list class="mat-elevation-z4">
    <div mat-subheader>Hochgeladene Dokumente</div>
    @for (doc of uploadedDocuments(); track doc.uploadedAt) {
        <mat-list-item>
            @if (!hideIcon()) {
                <mat-icon matListItemIcon>description</mat-icon>
            }
            <div matListItemTitle>
                <span class="title">{{ docTypesMap()[doc.documentType] }}</span>
                @if (!hideIcon()) {
                    <small class="state {{ doc.state }}">{{ doc.state }}</small>
                }
            </div>
            <div matListItemLine>
                {{ doc.uploadedAt | date: 'short' }}
            </div>
            @if (hideIcon()) {
                <div matListItemLine>
                    <small class="state {{ doc.state }}">{{ doc.state }}</small>
                </div>
            }
            <div matListItemMeta>
                <button
                    mat-icon-button
                    matTooltip="Dokument löschen"
                    [disabled]="
                        loading() || doc.new || doc.state === 'bestätigt'
                    "
                    aria-label="Dokument löschen"
                    (click)="
                        deleteDocumentClick.emit([
                            doc.documentType,
                            doc.uploadedAt,
                        ])
                    "
                >
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
            @if (doc.comment?.length) {
                <div matListItemLine matTooltip="gesamten Text anzeigen">
                    <a
                        href="javascript:void(0)"
                        (click)="openDialog(doc.comment)"
                        >{{ doc.comment }}</a
                    >
                </div>
            }
        </mat-list-item>
    } @empty {
        <mat-list-item>
            <div matListItemTitle>Noch keine Dokumente</div>
            @if (loading()) {
                <div matListItemLine>
                    <mat-progress-bar mode="buffer"></mat-progress-bar>
                </div>
            }
        </mat-list-item>
    }
</mat-list>
