import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApiClientService, FormControlErrorStateMatcher } from '@app/shared';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
    ],
})
export class LoginComponent {
    readonly loading = signal(false);

    readonly loginForm = new FormGroup({
        email: new FormControl('', [(Validators.required, Validators.email)]),
    });

    readonly matcher = new FormControlErrorStateMatcher();

    private readonly apiClient = inject(ApiClientService);
    private readonly router = inject(Router);
    private readonly snackbar = inject(MatSnackBar);

    async login(): Promise<void> {
        const email = this.loginForm.value.email;
        if (typeof email !== 'string' || this.loginForm.invalid) {
            this.snackbar.open('Eingabe ungültig', 'Ok', { duration: 30_000 });
            return;
        }
        this.loading.set(true);
        this.apiClient
            .sendMagicLink(email)
            .then((success) => {
                if (success) {
                    this.router.navigate(['/email-sent']);
                }
            })
            .finally(() => {
                this.loading.set(false);
            });
    }
}
