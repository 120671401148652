<h2 mat-dialog-title>Dokument hochladen</h2>
<mat-dialog-content>
    <p>Wähle zunächst die Art des Dokuments</p>
    <mat-form-field>
        <mat-label>Dokumentart</mat-label>
        <select
            matNativeControl
            [formControl]="documentTypeFormControl"
            cdkFocusInitial
        >
            <option selected disabled [value]="null">Dokumentart wählen</option>
            @for (documentType of data.documentTypes(); track documentType.id) {
                <option [value]="documentType.id">
                    {{ documentType.name }}
                </option>
            }
        </select>
    </mat-form-field>
    <br />
    <input
        #fileInput
        type="file"
        [accept]="ACCEPTED_FILE_TYPES"
        [formControl]="filesFormControl"
    />
    @if (filesFormControl.value === null) {
        <button
            mat-flat-button
            (click)="fileInput.click()"
            [disabled]="documentTypeFormControl.invalid"
        >
            Datei wählen
        </button>
    } @else {
        <mat-form-field>
            <mat-label>Ausgewählte Datei</mat-label>
            <input matNativeControl readonly [value]="selectedFileName()" />
            <button
                matSuffix
                mat-icon-button
                aria-label="Zurücksetzen"
                (click)="formGroup.reset()"
            >
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    }
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="cancel()">Abbrechen</button>
    <button mat-flat-button (click)="submit()" [disabled]="formGroup.invalid">
        Ok
    </button>
</mat-dialog-actions>
