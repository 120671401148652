import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';

export interface AlertData {
    title?: string;
    text: string;
}

@Component({
    templateUrl: './alert.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-alert',
    standalone: true,
    imports: [MatButtonModule, MatDialogModule, MatButtonModule],
})
export class AlertComponent {
    data: AlertData = inject(MAT_DIALOG_DATA);
}
