import { ChangeDetectionStrategy, Component, inject, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { SelectDocumentDialogComponent, SelectDocumentDialogData, SelectDocumentDialogResult } from '@app/components';

@Component({
    selector: 'app-select-document-button',
    templateUrl: './select-document-button.component.html',
    styleUrl: './select-document-button.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatButtonModule, MatIconModule],
})
export class SelectDocumentButtonComponent {
    readonly fileSelect = output<SelectDocumentDialogResult>();
    readonly dialog = inject(MatDialog);
    readonly disabled = input<boolean>();
    readonly documentTypes = input.required<{ id: string; name: string }[]>();

    openDialog(): void {
        const dialogRef = this.dialog.open<
            SelectDocumentDialogComponent,
            SelectDocumentDialogData,
            SelectDocumentDialogResult
        >(SelectDocumentDialogComponent, {
            data: {
                documentTypes: this.documentTypes,
            },
            minWidth: 'min(90vw, 500px)',
            maxWidth: 'min(100vw, 600px)',
            minHeight: 327,
        });

        dialogRef.beforeClosed().subscribe((result) => {
            if (result !== undefined) {
                this.fileSelect.emit(result);
            }
        });
    }
}
